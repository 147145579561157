import React from 'react';
import Layout from 'components/Layout';
import CrashedPage from 'features/CrashedPage';

const Error = () => (
  <Layout transparentHeader={true} paddingTop={false} withOutBodyPadding={true}>
    <CrashedPage
      seo="error"
      title={
        <>
          OOPS! <br /> something went wrong
        </>
      }
      titleFullWidth={true}
      buttonText="Go to Home Page"
      buttonPath="/"
    />
  </Layout>
);

export default Error;
